import Head from 'next/head'
import { PrismicMetaSingle } from '../../prismic-types-d'

type Props = {
  meta: PrismicMetaSingle['data']
}

export const CSeo = ({ meta }: Props) => {
  return (
    <Head>
      {meta.title && !!meta.title.length && <title>{meta.title[0].text}</title>}
      {meta.description && !!meta.description.length && (
        <meta name="description" content={meta.description[0].text} />
      )}
      {meta.twitter_site && !!meta.twitter_site.length && (
        <meta name="twitter_site" content={meta.twitter_site[0].text} />
      )}
      {meta.twitter_creator && !!meta.twitter_creator.length && (
        <meta name="twitter_creator" content={meta.twitter_creator[0].text} />
      )}
      {meta.twitter_title && !!meta.twitter_title.length && (
        <meta name="twitter_title" content={meta.twitter_title[0].text} />
      )}
      {meta.twitter_description && !!meta.twitter_description.length && (
        <meta
          name="twitter_description"
          content={meta.twitter_description[0].text}
        />
      )}
      {meta.facebook_title && !!meta.facebook_title.length && (
        <meta name="facebook_title" content={meta.facebook_title[0].text} />
      )}
      {meta.facebook_description && !!meta.facebook_description.length && (
        <meta
          name="facebook_description"
          content={meta.facebook_description[0].text}
        />
      )}
      {meta.share_image && (
        <>
          <meta name="share_image" content={meta.share_image.url} />
          <meta
            name="share_image_width"
            content={meta.share_image.dimensions.width.toString()}
          />
          <meta
            name="share_image_height"
            content={meta.share_image.dimensions.height.toString()}
          />
        </>
      )}
    </Head>
  )
}
